const Potato = {
    name: "Potato",
    harvestYield: 4,
    growthTime: 3,
    waterAbsorption: 1,
    stage_1: "🌱",
    stage_2: "🌱",
    stage_3: "🌱",
    price: 4,
    attack: 0,
    defense: 3, 
  };
  
  export default Potato;
  