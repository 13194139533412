const Corn = {
    name: "Corn",
    harvestYield: 2,
    growthTime: 2.5,
    waterAbsorption: 1.5,
    stage_1: "🌱",
    stage_2: "🌱",
    stage_3: "🌱",
    price: 6,
    attack: 2,
    defense: 2, 
  };
  
  export default Corn;
  